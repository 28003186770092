@import "_vars.scss";
@import url(//fonts.googleapis.com/earlyaccess/opensanshebrew.css);

.ReactModal__Content.popup {
  width: 80%;
  height: 80%;
  max-width: 1000px;
  max-height: 600px;
  overflow: visible !important;
  @media screen and (max-width: 768px) {
    height: 200px;
  }
  .watermark {
    position: absolute;
    z-index: 100;
    max-width: 200px;
    opacity: 0.5;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .logo {
    position: absolute;
    z-index: 2;
    left: 10px;
    top: 10px;
    width: 70px;
    padding: 0;
    &.width {
      width: 140px;
    }
    @media screen and (max-width: 768px) {
      width: 30px;
      top: 20px;

      &.width {
        width: 80px;
      }
    }
  }
  .download {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 1.4em;
    cursor: pointer;
    color: var(--color-primary);
    &:hover {
      color: var(--color-primary);
    }
  }
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .slick-prev,
  .slick-next {
    height: 30px;
    width: 30px;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 30px;
  }
  .slick-next {
    right: 15px;
  }
  .slick-prev {
    left: 15px;
    z-index: 1;
  }
  .gallery-slider {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    div {
      height: 100%;
    }
  }
  .slick-list {
    height: 100%;
  }
  .slick-track {
    height: 100%;
  }
  .slick-slide {
    height: 100%;
    width: 100%;
    .bg {
      height: 100%;
      width: 100%;
      position: relative;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
}
#public-asset-root {
  overflow-x: hidden;
  position: relative;
  height: 100%;
  width: 100%;

  &.rtl {
    text-align: right;
    direction: rtl;
    h2,
    p,
    span,
    a,
    h1,
    h3,
    h4,
    h5,
    h6,
    input,
    button,
    select {
      font-family: "Open Sans Hebrew", sans-serif;
    }
    .intro .info-container {
      .info,
      .title-container {
        float: right;
      }
      .square-button {
        margin-left: 0;
        margin-right: 10px;
        float: left;
      }
    }
    .floorPlans a svg,
    .floorPlans a i {
      margin-right: 0;
      margin-left: 6px;
    }
    .buttonBg {
      padding: 20px 10px 20px 20px;
      .hamburger {
        float: right;
        margin-left: 20px;
        margin-right: 0;
      }
    }
    .intro {
      .title-container {
        border-left: 25px solid transparent;
        border-right: none;
      }
      .info {
        left: auto;
        right: 0;
        padding-left: 20px;
        padding-right: 0;
      }
    }

    .contact a {
      float: right;
      clear: both;
    }
    .contact form input {
      text-align: right;
      float: right;
    }
    .right {
      float: left !important;
    }
    .left {
      float: right !important;
    }
  }

  nav {
    background-color: var(--color-primary);
    position: fixed;
    right: -280px;
    width: 280px;
    height: 100%;
    top: 0;
    transition: all 0.3s ease;
    padding-top: 40px;
    a {
      color: #fff;
      display: block;
      text-transform: uppercase;
      font-size: 1.4em;
      padding: 20px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      width: 70%;
      @include hCenter(relative);
      margin: 10px auto;
      transition: color 0.3s ease;
      cursor: pointer;
      &:hover {
        color: var(--color-secondary);
      }
    }
    &.open {
      right: 0;
    }
  }
  .content {
    overflow-x: hidden;
    overflow-y: scroll;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    transition: all 0.3s ease;
    &.open {
      right: 280px;
    }
  }
  font-weight: 300;
  .container {
    width: 90%;
    max-width: 1400px;
    z-index: 2;
    height: 100% !important;
    max-height: 100% !important;
    @include hCenter(relative);
  }
  .intro {
    height: 100vh;
    width: 100vw;
    position: relative;
    .container {
      max-width: 100%;
    }
    .logo {
      position: absolute;
      z-index: 2;
      left: 0;
      top: 40px;
      width: 100px;
      padding: 0;
      &.width {
        width: 200px;
      }
      @media screen and (max-width: 768px) {
        width: 70px;
        top: 20px;
        &.width {
          width: 140px;
        }
      }
    }
    .title-container {
      font-size: 1.2em;
      color: #fff;
      position: relative;
      float: left;
      width: auto;
      clear: both;
      top: -10px;
      padding: 15px 20px;
      min-width: 200px;
      height: auto;
      @media screen and (max-width: 768px) {
        font-size: 1em;
      }
      .color-container {
        opacity: 0.8;
        background: var(--color-secondary);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
      }
      h1 {
        font-family: "Heebo", sans-serif;
        font-weight: 300;
        font-style: normal;
        text-transform: uppercase;
        line-height: 1.2;
        position: relative;
        z-index: 2;
      }
    }
    .info-container {
      position: absolute;
      bottom: 130px;
      height: 45px;
      line-height: 45px;
      width: 100%;
      left: 0;
      @media screen and (max-width: 768px) {
        bottom: 10px !important;
        height: auto;
      }
      .square-button {
        float: right;
        margin: 0;
        margin-left: 10px;
        padding: 0;
        height: 100%;
        position: relative;
        text-align: center;
        background: var(--color-primary);
        border: none;
        color: #fff;
        width: 100px;
        svg,
        i {
          margin: 0 5px;
        }
        @media screen and (max-width: 768px) {
          width: 100%;
          margin: 5px 0 !important;
          top: 0;
          &.video-active {
            width: 45% !important;
            float: left;
            &.video-active.gallery-button {
              float: right;
            }
          }
        }
        &:hover {
          background: var(--color-secondary);
        }
      }
    }
    .info {
      font-size: 1em;
      height: 100%;
      float: left;
      left: 0;
      color: #fff;
      clear: both;
      background: rgba(0, 0, 0, 0.8);
      text-indent: 20px;
      padding-right: 20px;
      margin-bottom: 10px;
      text-transform: uppercase;
      @media screen and (max-width: 768px) {
        width: 100%;
        font-size: 0.9em;
      }
      .amenetis {
        text-align: center;
        span {
          margin: 0 6px;
        }
      }
      span {
        color: #fff;
        position: relative;
        @media screen and (max-width: 768px) {
          font-size: 0.8em;
        }
      }
    }
  }
  .intro .main-slider {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .slick-list {
    height: 100%;
  }
  .slick-track {
    height: 100%;
  }
  .slick-slide {
    height: 100%;
    width: 100%;
    &.slick-active .bg {
      transform: scale(1);
    }
    .bg {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      transition: all 8s cubic-bezier(0.7, 0, 0.3, 1);
      transform: scale(1.2);
    }
  }

  .section {
    width: 100%;
    position: relative;
    h2 {
      font-size: 2em;
      margin-bottom: 30px;
      color: var(--color-secondary);
    }
  }
  .h-610 {
    height: 610px;
    @media screen and (max-width: 768px) {
      height: auto;
    }
  }
  .col-6 {
    @media screen and (max-width: 768px) {
      max-width: 100% !important;
      display: block !important;
      flex: none !important;
    }
  }
  .h-400 {
    height: 400px;
    @media screen and (max-width: 768px) {
      height: auto;
    }
  }
  .overview,
  .contact {
    background: #fff;
    .container {
      max-width: none !important;
    }
    .left {
      padding: 80px 0;
      max-height: 400px;
      width: 60%;
      @media screen and (max-width: 768px) {
        width: 100%;
        max-height: none;
      }
    }
    &.overview {
      .left {
        display: block;
        width: 50%;
        @media screen and (max-width: 768px) {
          width: 100% !important;
        }
      }
      .right {
        width: 50%;
        @media screen and (max-width: 768px) {
          width: 100% !important;
        }
      }
    }
    .right {
      width: 40%;
      height: 400px;
      iframe {
        @include fullpage;
      }
    }

    a.map {
      float: left;
      font-size: 1em;
      text-align: center;
      display: inline-block;
      margin-right: 10px;
      margin-top: 10px;
      display: none;
      @media screen and (max-width: 768px) {
        width: 100%;
        display: block;
        margin-right: 0;
        margin-top: 10px;
      }
    }
    .line {
      margin: 40px 0;
      width: 100%;
      display: none;
      border: 1px dashed rgba(210, 210, 210, 0.5);
    }
  }

  .tour {
    height: 80vh;
    padding: 0;
    .clearfix {
      width: 100%;
      height: 65px;
      background: var(--color-primary);
      line-height: 65px;
      h2 {
        font-size: 2em;
        color: #fff;
        text-align: center;
        margin: 0;
      }
    }
    .inner {
      position: relative;
      height: calc(100% - 65px);
      width: 100%;
    }
    .bg {
      @include fullpage;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: absolute;
      z-index: 1;
    }
    .overlay {
      position: absolute;
      @include fullpage;
      background: rgba(0, 0, 0, 0.6);
      z-index: 2;
      text-align: center;
      .text {
        height: 200px;
        width: 100%;
        @include vCenter(absolute);
      }
      h2 {
        font-family: "Heebo", sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 3em;
        color: #fff;
      }
      img {
        height: 100px;
        cursor: pointer;
      }
    }
    iframe {
      @include fullpage;
      position: absolute;
      z-index: 1;
    }
  }

  .contact {
    padding: 0;
    .left {
      padding-bottom: 0;
      @media screen and (max-width: 768px) {
        padding: 50px 0;
      }
    }
    form {
      padding-bottom: 120px;
      input {
        background: transparent;
        height: 50px;
        line-height: 50px;
        font-size: 1.1em;
        max-width: 70%;
        border-bottom: 1px solid rgba(210, 210, 210, 0.5);
        margin-bottom: 10px;
      }
      a {
        margin-top: 10px;
        display: block;
      }
    }
    .right {
      height: 500px;
      background-color: var(--color-primary);
      position: relative;
      @media screen and (max-width: 768px) {
        width: 100% !important;
      }
      .lg {
        height: 160px;
        text-align: center;
        width: 100%;
        z-index: 2;
        @include superCenter(absolute);
        img {
          height: 120px;
          display: block;
          @include hCenter(relative);
          margin-bottom: 30px;
        }
        a {
          color: #fff;
          margin: 0 10px;
          font-size: 1.6em;
          transition: all 0.3s ease;
          clear: none !important;
          float: none !important;
          display: inline;
          cursor: pointer;
          &:hover {
            color: var(--color-primary);
          }
        }
      }
      .overlay {
        @include fullpage;
        position: absolute;
        top: 0;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='152' height='152' viewBox='0 0 152 152'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='temple' fill='%23d4d4d4' fill-opacity='0.4'%3E%3Cpath d='M152 150v2H0v-2h28v-8H8v-20H0v-2h8V80h42v20h20v42H30v8h90v-8H80v-42h20V80h42v40h8V30h-8v40h-42V50H80V8h40V0h2v8h20v20h8V0h2v150zm-2 0v-28h-8v20h-20v8h28zM82 30v18h18V30H82zm20 18h20v20h18V30h-20V10H82v18h20v20zm0 2v18h18V50h-18zm20-22h18V10h-18v18zm-54 92v-18H50v18h18zm-20-18H28V82H10v38h20v20h38v-18H48v-20zm0-2V82H30v18h18zm-20 22H10v18h18v-18zm54 0v18h38v-20h20V82h-18v20h-20v20H82zm18-20H82v18h18v-18zm2-2h18V82h-18v18zm20 40v-18h18v18h-18zM30 0h-2v8H8v20H0v2h8v40h42V50h20V8H30V0zm20 48h18V30H50v18zm18-20H48v20H28v20H10V30h20V10h38v18zM30 50h18v18H30V50zm-2-40H10v18h18V10z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        opacity: 0.1;
      }
    }
  }

  .floorPlans {
    position: relative;
    padding-bottom: 10px;
    @media screen and (max-width: 768px) {
      height: 500px !important;
    }
    .plans {
      width: 100%;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      text-align: center;
      height: 60px;
      line-height: 60px;
      span {
        display: inline-block;
        margin: 0 10px;
        font-size: 1.1em;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 200px;
        white-space: nowrap;
        @media screen and (max-width: 768px) {
          margin: 0 5px;
          font-size: 1em;
        }
        &:hover,
        &.selected {
          color: var(--color-primary);
        }
      }
    }
    a {
      text-align: center;
      svg,
      i {
        margin-right: 6px;
      }
    }
    .plan {
      text-align: center;
      display: block;
      @include hCenter(relative);
      margin: 5px auto;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      @media screen and (max-width: 768px) {
        width: 90%;
      }
    }
    .h-67 {
      height: 67%;
    }
  }

  .social {
    height: auto;
    padding: 30px 0;
    width: 100%;
    background: #1d1d1d;
    text-align: center;
    h3 {
      padding-bottom: 20px;
      font-size: 1.8em;
      color: #fff;
      text-transform: uppercase;
    }
    a {
      color: #fff;
      font-size: 1.2em;
      border: 2px solid #fff;
      border-radius: 50%;
      padding: 0;
      margin: 0 5px;
      height: 40px;
      width: 40px;
      line-height: 35px;
      display: inline-block;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        color: var(--color-primary);
        border-color: var(--color-primary);
      }
    }
  }

  footer {
    text-align: left;
    background: var(--color-primary);
    height: 80px;
    width: 100%;
    clear: both;
    line-height: 80px;
    @media screen and (max-width: 768px) {
      height: 160px;
      line-height: 1.4;
      text-align: center;
    }
    span,
    h6 {
      color: #fff;
      font-size: 1em;
    }
    h6 {
      float: right;
      @media screen and (max-width: 768px) {
        float: none;
        margin-top: 40px;
      }
    }
    img {
      height: 40px;
      position: relative;
      top: 18px;
      margin-left: 20px;
    }
  }
}
